
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































.job-hero__details {
  padding: $spacing 0;
  border-top: 1px solid $c-light-grey;
  border-bottom: 1px solid $c-light-grey;

  @include mq(m) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.job-hero__details__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;

  .icon {
    margin-right: math.div($spacing, 2);
    color: currentColor;
  }

  @include mq(m) {
    & + & {
      margin-left: $spacing * 2;
    }
  }
}
