
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































.job-single__content {
  ::v-deep {
    .cpt-blog {
      @include default-margin(0.6);

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.job-single__link {
  @include mq(s) {
    margin-left: col(1, 10);
  }

  @include mq(l) {
    margin-left: col(2, 10);
  }
}
